import axios        from 'axios'

/*axios.defaults.transformResponse = [function (data, headers) {
    return Object.values(JSON.parse(data))[0]
  }]
  */

console.log(process.env);
axios.defaults.withCredentials = true;

if(process.env.NODE_ENV === "development"){
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_DEVELOPMENT
}
else{
  axios.defaults.baseURL = process.env.REACT_APP_SERVER
}



export default null