import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom'
import axios from 'axios';
import { MyContext } from "./myProvider"
import ResetLocation from './resetLocation'




class Register extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      redirect: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  handleSubmit(event) {
    let value = this.context;
    event.preventDefault();
    axios.post("/register",
      {
        username: document.getElementById('username').value,
        password: document.getElementById('password').value,
        email: document.getElementById('email').value,
      }).then(res => {
      console.log(res);
      let value = this.context;
      value.setUser(res.data)
      value.setLocation(null)
      this.setState({redirect: "/"})
    }).catch(err => {
      this.setState({ error: err.response.data })
    });
    event.target.reset()
  }
  handleClick = (params) => {
    let value = this.context
    value.setLocation("login")
  }


  render() {
    let value = this.context;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="register">
        <div className="user-control-form">
          {this.state.error && <p className="error">{this.state.error}</p>}
          <ResetLocation icon={<i className="fas fa-times"></i>}/>
          <h1 className="textcenter" >Register</h1>
          <form className="user-form" onSubmit={this.handleSubmit}>
            <input type="text" id="username" name="username" placeholder="Username" />
            <input id="email" type="email" name="email" placeholder="Email" />
            <input id="password" type="password" name="password" placeholder="Password" />
            <input type="submit" value="Sign Up" />
          </form>
          <p>Already have an account? <button onClick={this.handleClick}>Login</button></p>

        </div>
      </div>

    )
  }
}

export default Register;
